import PreviousMap from 'postcss/lib/previous-map'
import React from 'react'

export default function Card (props) {
   
    return (
          
              <div key={props.index} className="group relative basis-1/2 sm:basis-1/4 shrink-0">
                <div className="w-full min-h-80 bg-gray-200  rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 ">
                  <img
                    src={props.img}
                    alt={props.imgAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <a href={props.link} target="_blank">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {props.title}
                      </a>
                    </h3>
                    {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                  </div>
                  <p className="text-sm font-medium text-gray-900">{props.price}</p>
                </div>
              </div>
    )
  }