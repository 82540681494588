import { React, useState, useEffect } from 'react'
import './App.css';
import Main from './components/Main'
import Navbar from './components/Navbar';
import Overlay from './components/Overlay';


// fetch my grow zone data on page load so that it's available when needed
function App() {
  const [hasGrowingZone, setHasGrowingZone] = useState(false)
  const [growingZone, setGrowingZone] = useState("")
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [overlayMessage, setOverlayMessage] = useState("")
  const [zoneData, setZoneData] = useState()
  const [filteredZoneData, setFilteredZoneData] = useState()

  useEffect(() => {
    fetch(`https://raw.githubusercontent.com/michaelbiggers/popular-plant-scraper/main/plantData.json`)
     .then(response => response.json())
     .then(data => setZoneData(data))
   }, []);

  let userLocation = navigator.geolocation;
  function handleLocation() {
    if(userLocation)  {
      setOverlayMessage("Detecting your location...")
      setOverlayVisible(true)
      userLocation.getCurrentPosition(convertToZip, showError)
    }
    else{console.log("No support for geo")}
  }
  
  function convertToZip(position){
    let requestURL = `https://secure.geonames.org/findNearbyPostalCodesJSON?lat=${position.coords.latitude}&lng=${position.coords.longitude}&username=growingZone`
    setOverlayMessage("Processing ZIP code...")
    fetch(requestURL)
        .then(response => {
          return response.json()
        })
        .then(data => {
          getGrowZone(data.postalCodes[0].postalCode)

        })
  }
  function showError(error){
    if(error.PERMISSION_DENIED) {
      console.log("You denied the request for geo")
    }
  }
  function processInput() {
    setOverlayMessage("Processing ZIP code...")
    !overlayVisible ? setOverlayVisible(true) : setOverlayVisible()
    let input = document.querySelector("#zip-input").value
    getGrowZone(input)
  }
  function getGrowZone(zipCode){
    let requestURL = `https://phzmapi.org/${zipCode}.json`

    fetch(requestURL)
      .then(response=> {
        return response.json()
      })
      .then(data => {
        setGrowingZone(data.zone)
        setHasGrowingZone(true)
        setOverlayVisible(false)
        setOverlayMessage("")
      })
  }
  useEffect(() => {
    if(growingZone ) {
      filterZones(growingZone)
    }
    
   }, [growingZone]);

  function filterZones(x) {
    let cleanZone = x.replace(/\D+/g, '');
    const filteredZone = zoneData.filter(item => item.zone == cleanZone)
    setFilteredZoneData(filteredZone)
  }
  return (
    <div className="App">
    {/* <Navbar
      hasGrowingZone={hasGrowingZone}
      growingZone={growingZone}></Navbar> */}
     <Main 
      getLocation={handleLocation}
      growingZone={growingZone}
      setGrowingZone={setGrowingZone}
      hasGrowingZone={hasGrowingZone}
      setHasGrowingZone={setHasGrowingZone}
      processInput={processInput}
      filteredZoneData={filteredZoneData}
      ></Main>
      {overlayVisible && 
        <Overlay
          overlayMessage={overlayMessage}></Overlay>}
    </div>
  )
}

export default App;
