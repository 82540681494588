import React from 'react'
import logo from '../img/logo-horizontal.png'
import ProductSection from './ProductSection';

function Main(props) {

    return (

        <section className="min-h-screen flex items-center justify-center  bg-[url('../public/img/page-bg.jpg')] bg-center bg-cover bg-fixed">
            <div className="min-h-screen min-w-full flex content-center justify-center flex-wrap backdrop-blur-sm">
            <div className="p-4 w-5/6 h-1/2 text-center bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <a className = "basis-1/3 text-center" href="/"><img className="max-w-xs mx-auto mb-10" src={logo} /></a>
                <h5 className="mb-2 text-3xl font-bold text-gray-900 dark:text-white">
                    {props.hasGrowingZone &&  
                        `Your Zone: ${props.growingZone}`}
                </h5>
                {!props.hasGrowingZone &&
                    <>
                        <div className="flex items-center justify-center space-x-3">
                            <button className="bg-transparent hover:bg-emerald-700 text-emerald-900 font-semibold hover:text-white py-2 px-4 border border-emerald-900 hover:border-transparent rounded" onClick={props.getLocation}>Use Location</button>
                            <span>or</span>
                            <input className = "bg-transparent text-emerald-900 font-semibold py-2 px-4 border border-emerald-900 rounded text-center placeholder:text-emerald-900" placeholder="Enter a ZIP code" size = "14" id="zip-input" />                        
                        </div>
                        <button className ="bg-emerald-900 hover:bg-emerald-700 text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mt-6" onClick={props.processInput} >Get My Zone</button>
                    </>
                }
                
                {props.hasGrowingZone && 
                    <button className="italic text-sm" onClick={function(event){props.setHasGrowingZone(false); props.setGrowingZone("")}}>Check a different ZIP code</button>
                }
                {props.filteredZoneData && 
                    <>
                    <ProductSection
                            filteredZoneData={props.filteredZoneData}
                            category="Evergreen"
                        ></ProductSection><ProductSection
                            filteredZoneData={props.filteredZoneData}
                            category="Shade"
                        ></ProductSection><ProductSection
                            filteredZoneData={props.filteredZoneData}
                            category="Flowering"
                        ></ProductSection>
                        </>
                }
            </div>

            </div>
            
        </section>
    )

}

export default Main