import React from 'react'

function Overlay (props) {
    return(
        <div className = "overlay absolute top-0 right-0 bottom-0 left-0 bg-black opacity-80 flex justify-center items-center z-20">
            <p className="text-white italic">{props.overlayMessage || "Processing"}</p>
        </div>
    )
}

export default Overlay