import React from 'react'
import Card from './Card.js'

export default function ProductSection(props) {
    // take filteredZoneData and then filter it to match a category
    // with that data, render a ProductSection with a Card for each item
    const sectionData = props.filteredZoneData.filter(item => item.category == props.category).map((product) => (
        <Card
            index={product.index}
            img={product.img}
            imgAlt={product.imgAlt}
            link={product.link}
            title={product.title}
            price = {product.price}
        ></Card>
    ))

    return (
        <section>
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 text-left ">{props.category} Trees</h2>
            
            <div className="mt-6 flex overflow-x-scroll gap-y-10 gap-x-6  xl:gap-x-8">
                {sectionData}
            </div>
            
        </section>
    )
}